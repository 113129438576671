<template>
  <div class="app-container">
    <CrudTable entity="DepositClass" :columns="columns" />
  </div>
</template>

<script>
import CrudTable from '@/components/crud/CrudTable';
export default {
  components: {
    CrudTable
  },
  data() {
    return {
      columns: ['name']
    };
  }
};
</script>

<style scoped></style>
